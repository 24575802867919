<template>
    <form class="space-y-4 md:space-y-6" @submit.prevent="login">
        <div>
            <label
                for="email"
                class="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
            >
                Email
            </label>
            <input
                v-model="form.email"
                type="email"
                name="email"
                id="email"
                class="text-input"
                placeholder="email@ticto.com"
                required
            />
        </div>
        <div>
            <label
                for="password"
                class="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
            >
                Senha
            </label>
            <div class="relative">
                <input
                    v-model="form.password"
                    :type="showPassword ? 'text' : 'password'"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    class="text-input"
                    required
                />
                <button
                    type="button"
                    class="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-200"
                    @click="showPassword = !showPassword"
                >
                    <font-awesome-icon
                        :icon="showPassword ? ['fas', 'eye-slash'] : ['fas', 'eye']"
                    />
                </button>
            </div>
        </div>
        <submit-button :loading="loading" class="w-full">
            Entrar
        </submit-button>

        <div class="text-sm text-center">
            <router-link
                :to="{ name: 'password.request' }"
                class="text-primary-600 hover:underline dark:text-white"
            >
                Esqueceu sua senha?
            </router-link>
        </div>
    </form>
</template>

<script>
import { mapWritableState } from "pinia";
import { Store } from "../../stores/store";
import { useReCaptcha } from "vue-recaptcha-v3";
import SubmitButton from "../../components/forms/SubmitButton.vue";

export default {
    components: { SubmitButton },
    data() {
        return {
            form: {
                gToken: "",
                email: "",
                password: "",
            },
            loading: false,
            showPassword: false,
        };
    },

    computed: {
        ...mapWritableState(Store, ["user", "token"]),
    },

    async mounted() {
        const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
        await recaptchaLoaded();
        this.form.gToken = await executeRecaptcha("login");
    },

    methods: {
        async login() {
            try {
                this.loading = true;
                await axios.get("/sanctum/csrf-cookie");
                const response = await axios.post("/api/login", {
                    gToken: this.form.gToken,
                    email: this.form.email,
                    password: this.form.password,
                });
                this.user = response.data;
                this.$router.push({ name: "users" });
            } catch (error) {
                if (error.response) {
                    if (
                        error.response.data.exception &&
                        error.response.data.device_uuid
                    ) {
                        this.$router.push({
                            name: "validateDevice",
                            params: { uuid: error.response.data.device_uuid },
                        });
                    } else if (error.response.data.expired) {
                        this.$notify(
                            {
                                group: "error",
                                title: "Senha Expirada",
                                text: error.response.data.message,
                            },
                            4000,
                        );
                        setTimeout(() => {
                            window.open(
                                `https://dash.ticto.com.br/remember?email=${this.form.email}`,
                                "_blank",
                            );
                        }, 1000);
                        this.$router.push({ name: "login" });
                    } else {
                        this.$notify(
                            {
                                group: "error",
                                title: "Error",
                                text: error.response.data.message,
                            },
                            4000,
                        );
                    }
                }
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
