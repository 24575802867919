<template>
    <back-button :route="{ name: 'audit' }" />
    <h1 class="text-lg font-bold text-gray-900 dark:text-white flex items-center">
        Auditoria #{{ audit.hash }}

        <submit-button
            class="ml-1"
            @click="syncClickUp(audit.id)"
            :disabled="isSyncing(audit.id)"
            :loading="isSyncing(audit.id)"
            size="sm"
        >
            Clickup
        </submit-button>

        <a
            v-if="audit.clickup_id"
            :href="`https://app.clickup.com/t/${audit.clickup_id}`"
            target="_blank"
            class="btn-outline-primary-sm text-sm ml-1 px-3"
        >
            Task
            <ArrowTopRightOnSquareIcon class="h-5 w-5" aria-hidden="true" />
        </a>
    </h1>

    <div class="flex justify-between">
        <user-heading :user="audit.user" v-if="audit" />
        <audit-actions
            v-if="audit"
            :audit="audit"
            @refund="refundAll = true"
            @export-pdf="exportPdf"
            @fetch="fetch"
        />
    </div>
    <div v-if="audit && audit.refund_deadline">
        <div
            class="my-4 border-l-4 border-yellow-500 bg-yellow-100 p-4 text-yellow-700"
            role="alert"
        >
            <p class="font-bold">Atenção</p>
            <p>
                O usuário não apresendou contestaço. O reembolso das vendas
                deve ocorrer até {{ audit.refund_deadline }}.
            </p>
        </div>
    </div>
    <div class="grid grid-cols-4 gap-4">
        <div class="col-span-3">
            <info-col title="BLOQUEIOS" class="col-span-4 mt-2 hidden" />
            <div class="my-3 grid hidden grid-cols-4 gap-3">
                <card :loading="loading">
                    <info-col title="SALDO" :loading="loading">
                        <boolean-icon :boolean="!audit.block_cashout" />
                    </info-col>
                </card>
                <card :loading="loading">
                    <info-col title="SAQUES" :loading="loading">
                        <boolean-icon :boolean="!audit.block_cashout" />
                    </info-col>
                </card>
                <card :loading="loading">
                    <info-col title="PRODUTOS" :loading="loading">
                        <boolean-icon :boolean="setting.can_anticipate" />
                    </info-col>
                </card>
            </div>
            <refund-transactions
                v-if="audit && refundAll"
                :audit="audit"
                class="mb-3"
            />
            <card>
                <div class="flex flex-col dark:text-white">
                    <div class="flex flex-col">
                        <div class="flex items-center">
                            <ClockIcon class="mr-1 h-4 w-4" />
                            Iniciado em {{ audit.created_at }}
                        </div>
                    </div>

                    <div
                        class="mb-2 rounded-lg bg-white p-3 shadow-md dark:bg-gray-500"
                    >
                        <p class="text-gray-700 dark:text-gray-300">
                            Este conteúdo gerado por IA pode conter erros. Por
                            favor, revise e verifique as informações.
                        </p>
                    </div>
                    <markdown-renderer
                        class="mb-5 mr-auto rounded-lg shadow"
                        v-for="message in audit.messages"
                        :source="message.message"
                    />
                </div>
                <chat v-model="message" @generated="storeMessage" />
            </card>

            <nav-tabs
                class="mb-3"
                :exact="false"
                v-if="audit"
                :tabs="[
                    {
                        label: 'Interações',
                        route: 'audit.interactions',
                        params: { audit_id: audit.id },
                        icon: UsersIcon,
                    },
                    {
                        label: 'Métricas',
                        route: 'audit.metrics',
                        params: { audit_id: audit.id },
                        icon: UsersIcon,
                    },
                    {
                        label: 'Clicks',
                        route: 'audit.trackings',
                        params: { audit_id: audit.id },
                        icon: UsersIcon,
                    },
                    {
                        label: 'Produtos',
                        route: 'audit.products',
                        params: { audit_id: audit.id },
                        icon: CubeIcon,
                    },
                    {
                        label: 'Vendas',
                        route: 'audit.commissions',
                        params: { audit_id: audit.id },
                        icon: BanknotesIcon,
                    },
                    {
                        label: 'Logins',
                        route: 'audit.logins',
                        params: { audit_id: audit.id },
                        icon: UserIcon,
                    },
                    {
                        label: 'Financeiro',
                        route: 'audit.finnancial',
                        params: { audit_id: audit.id },
                        icon: CurrencyDollarIcon,
                    },
                ]"
            />
            <router-view
                v-if="audit"
                :user="audit.user"
                :audit="audit"
                @fetch="fetch"
            />
        </div>
        <div>
            <audit-interactions
                :audit="audit"
                @fetch="fetch"
                :show-card="true"
                :show-interactions="false"
            />
        </div>
    </div>
</template>

<script>
import Chat from "../../components/ai/Chat.vue";
import MarkdownIt from "markdown-it";
import BackButton from "../../components/layouts/navigation/BackButton.vue";
import UserHeading from "../users/UserHeading.vue";
import Card from "../../components/layouts/Card.vue";
import InfoCol from "../../components/layouts/InfoCol.vue";
import BooleanIcon from "../../components/layouts/BooleanIcon.vue";
import TimelineSkeleton from "../../components/layouts/skeleton/TimelineSkeleton.vue";
import AuditInteractions from "../../components/audit/AuditInteractions.vue";
import {
    UsersIcon,
    CubeIcon,
    BanknotesIcon,
    UserIcon,
    CurrencyDollarIcon,
    ExclamationCircleIcon,
    ClockIcon,
} from "@heroicons/vue/24/outline";
import NavTabs from "../../components/layouts/NavTabs.vue";
import Navigation from "../../components/layouts/navigation/vertical/Navigation.vue";
import SubmitButton from "../../components/forms/SubmitButton.vue";
import RefundTransactions from "../../components/audit/RefundTransactions.vue";
import { mapWritableState } from "pinia";
import { Store } from "../../stores/store";
import MarkdownRenderer from "../../components/layouts/MarkdownRenderer.vue";
import ScoreGauge from "../../components/user/score/ScoreGauge.vue";
import Score from "../../components/user/Score.vue";
import AuditActions from "./AuditActions.vue";
import { useToast } from "vue-toastification";
import { ArrowTopRightOnSquareIcon } from "@heroicons/vue/24/outline";
import { ref, onMounted } from 'vue';

export default {
    components: {
        Chat,
        AuditActions,
        Score,
        ScoreGauge,
        MarkdownRenderer,
        RefundTransactions,
        SubmitButton,
        ExclamationCircleIcon,
        NavTabs,
        Navigation,
        AuditInteractions,
        TimelineSkeleton,
        BooleanIcon,
        InfoCol,
        Card,
        UserHeading,
        BackButton,
        ClockIcon,
        ArrowTopRightOnSquareIcon,
    },
    data() {
        return {
            refundAll: false,
            generating: false,
            message: "",
            loading: false,
            audit: false,
            syncing: false,
            approve: false,
        };
    },

    computed: {
        ...mapWritableState(Store, ["authUser"]),
        setting() {
            if (!this.audit) {
                return false;
            }
            return this.audit.user.setting;
        },
    },

    setup(props) {
        const markdown = new MarkdownIt();
        const toast = useToast();
        const syncStates = ref({});

        const isSyncing = (auditId) => {
            return ['syncing', 'locked'].includes(syncStates.value[auditId]);
        };

        const syncButtonText = (auditId) => {
            const status = syncStates.value[auditId];
            switch (status) {
                case 'syncing': return 'Sincronizando...';
                case 'locked': return 'Já está sincronizando...';
                case 'failed': return 'Tentar novamente';
                default: return 'Sincronizar ClickUp';
            }
        };

        const syncClickUp = async (auditId) => {
            try {
                syncStates.value[auditId] = 'syncing';
                await axios.post(`/api/audit/${auditId}/clickup`);
            } catch (error) {
                syncStates.value[auditId] = 'failed';
                throw error;
            }
        };

        return {
            syncStates,
            isSyncing,
            syncButtonText,
            syncClickUp
        };
    },

    mounted() {
        this.fetch();

        Echo.channel("audit." + this.$route.params.audit_id)
            .listen(".App\\Events\\Audit\\GeneratingDescription", (e) => {
                this.generating = e.generating;
                if (!e.generating) {
                    this.fetch();
                }
            })
            .listen('.App\\Events\\ClickUpSyncStatusEvent', (e) => {
                this.syncStates[e.audit_id] = e.status;

                if (e.status === 'completed') {
                    this.fetch();
                }
            });
    },
    methods: {
        exportPdf() {
            window.open("/api/audit/" + this.$route.params.audit_id + "/pdf");
        },
        storeMessage() {
            axios
                .post(
                    "/api/audit/" + this.$route.params.audit_id + "/messages",
                    {
                        message: this.message,
                    },
                )
                .then(({ data }) => {
                    console.log({ data });
                });
        },
        generateReport() {
            this.generating = true;
            axios
                .get("/api/audit/" + this.$route.params.audit_id + "/report")
                .then(({ data }) => {});
        },
        fetch() {
            this.loading = true;

            axios
                .get("/api/audit/" + this.$route.params.audit_id)
                .then(({ data }) => {
                    this.audit = data;
                    this.loading = false;
                });
        },
        async syncWithClickUp() {
            try {
                this.syncing = true;
                await axios.post(`/api/audit/${this.$route.params.audit_id}/clickup`);
                this.toast.success("Task sincronizada com sucesso!");
            } catch (error) {
                console.error(error);
                this.toast.error("Erro ao sincronizar com ClickUp");
            } finally {
                this.syncing = false;
            }
        },
        UsersIcon,
        CubeIcon,
        BanknotesIcon,
        UserIcon,
        CurrencyDollarIcon,
    },
};
</script>
